<template>
  <v-container>
    <v-card outlined rounded-pill max-width="100%" class="mx-auto pa-1 mb-2" >
      <v-layout wrap>
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 200px;"
          id="company" 
          ref="company" 
          label="경영체✽" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;" 
          id="farm" 
          ref="farm" 
          label="농장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;"
          id="building" 
          ref="building" 
          label="축사✽" 
          v-model="building"
          :items="buildingItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeBuilding()"
          @focus="comboBuildings()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card>

    <v-card :loading="loading" nowrap height="800px" outlined class="table mb-2"> 
      <div id="seasonTable">
        <div class="season_header" style="display:flex">
          <div style="display: flex;"> 
            <div class="table_title"> 체중 \ 계절 </div>
            <div class="season_section" tabindex="-1" :class="{ cursorpointer: edit_mode, set_click:edit_mode }" v-for="(header_season,hs) in header_season" :key="hs" @click="updateSeasonRange(header_season)"> 
              <div style="font-size:16px;"> {{header_season.season_name}} </div>
              <div class="header_date">{{ dateMD(header_season.season_from) }}-{{dateMD(header_season.season_to)}}</div>
            </div>
        </div>
        <v-dialog
            v-model="dialogSeason"
            max-width="350" 
          >
            <template v-slot:activator="{ on, attrs }">            
              <v-btn outlined class="season_btn elevation-3" 
                style="height:50px; width:130px;" v-show="edit_mode  && header_weight.length"
                v-bind="attrs"
                v-on="on" 
                @click="newSeason">
                <v-icon
                color=white
              >
                mdi-plus
              </v-icon>
                계절추가
              </v-btn>
            </template>
              <SeasonSet class="pa-0"
                :season_setData="season_setData"
                @close="dialogSeason = false"
                @refresh="refresh"
                @checkDuplicate="seasonDuplicate"
              />
          </v-dialog>
        </div>
      
      <div>
        <div style="display:flex">
          <div class="weight_header">
            <div>
              <div class="weight_section" tabindex="-1" :class="{ cursorpointer: edit_mode, set_click:edit_mode }"
                   v-for="(header_weight,hw) in header_weight" :key="hw" 
                   @click="updateWeightRange(header_weight)"> 
                <div style="font-size:16px"> {{header_weight.weight_name}} </div>
                <div class="weight_des"> {{header_weight.weight_min}} - {{header_weight.weight_max}}㎏</div>
                <div class="weight_des" v-if="header_weight.age_min"> {{header_weight.age_min}} - {{header_weight.age_max}}주 </div>
              </div>
            </div>

          <!-- 체중구간 추가 팝업 -->
          <v-dialog
            v-model="dialogWeight"
            max-width="400" 
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined class="weight_btn elevation-3" style="height:50px; width:130px;" v-show="edit_mode"
                v-bind="attrs"
                  v-on="on"
                  @click="newWeight">
                <v-icon
                  color=white
                >
                  mdi-plus
                </v-icon>
                체중추가
              </v-btn>
            </template>
              <WeightSet class="pa-0"
                :setData="setData" 
                @close="dialogWeight = false"
                @refresh="refresh"
                @checkDuplicate="weightDuplicate"
              />
          </v-dialog>
        </div>

        <div style="display:flex">
          <div class="controlContents">
              <div v-for="(weight,w) in weight_list" :key="w">
                <v-layout>
                  <div v-for="(season,s) in weight.season_list" :key="s">
                    <div class="setting" tabindex="-1" v-if="season.season_id" :class="{cursorpointer:edit_mode, set_click:edit_mode}" @click="updateControlRange(weight, season)"> 
                    <div class="fc_info" v-if="season.fan" >
                      <div> 1차 : {{season.fan.set_temp1}} / {{season.fan.dev_temp1}} </div>
                      <div> 2차 : {{season.fan.set_temp2}} / {{season.fan.dev_temp2}} </div>
                      <div> 3차 : {{season.fan.set_temp3}} / {{season.fan.dev_temp3}}</div>
                    </div>
                  
                    <div class="heater_info" v-if="season.heater">
                      <div> {{ season.heater.on_off1 ?  `1차 : ${season.heater.set_temp1} / ${season.heater.dev_temp1}` : "OFF" }} </div> 
                    </div>
                  </div>
                  </div>        
                  <div class="set_info">
                    <div class="fan"> 팬 </div>
                    <div class="heater"> 보온등 </div>
                  </div>
              </v-layout>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>

      <!--컨트롤러 세팅 pop-up-->
      <v-dialog
        v-model="dialogSet"
        max-width="500"
      >
        <ControllerSet class="pa-0"
        :control_setData="control_setData"
        @close="dialogSet = false"
        @refresh="refresh"/>
      </v-dialog>

      <v-row class="justify-center">
        <v-btn v-if="$getters.userInfo.grade < 'UT06'" outlined rounded class="modify_btn elevation-3" 
          style="height:50px; width:130px; font-size:18px;" 
          @click="edit_mode = !edit_mode; $store.commit('resMessage','')"> 
          {{edit_mode ? "나가기" : "수정하기"}}
        </v-btn>
      </v-row>
    </v-card>

  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js"; 

import WeightSet from './child/WeightSet.vue';
import SeasonSet from './child/SeasonSet.vue';
import ControllerSet from './child/ControllerSet.vue';
const year = dateUtil.format(new Date(), "yyyy-");

export default {
  name: "ControlTable",

  components: {
    WeightSet,
    SeasonSet,
    ControllerSet
  },

  async created() {
    Apis.pageCount({
        page_name: this.$route.name,
        url: this.$route.path,
        division: "pigro",
    }, () => {
    }).catch((err) => {
        console.log("pageCount 호출 오류", err);
    });
    await this.comboCompanies();
    await this.comboFarms();
    await this.comboBuildings();
    
    let cookie_info = VueCookies.get("controltable_info"); // 이전 조회정보를 참조
    if (!this.isNull(this.$route.params)) {
    // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
      if ( this.$route.params.building && this.$route.params.building.code) {
        this.building = this.$route.params.building
      } else { 
          await this.comboBuildings();
          this.building = this.buildingItems[0];
      }
    } else if (cookie_info) {
          this.company = cookie_info.company || {};
          this.farm = cookie_info.farm || {};
          this.building = cookie_info.building || {};
    } else { // 이전 조회정보가 없을 경우 첫번째 농장/사업장 디폴트로 참조
          this.building = this.buildingItems[0];
          this.company = { code: this.building.company_cd, name: this.building.company_name };
          this.farm = { code: this.building.farm_cd, name: this.building.farm_name };
    }
    this.refreshList();
  },

  data() {
    return {
      company: {},
      companyItems: [],
      farm: {},
      farmItems: [],
      building: {},
      buildingItems: [],
      loading: false,
      weight_list: [],
      season_list: [],
      header_season: [],
      header_weight: [],
      edit_mode: false,
      combobox: true,
      data_text: "",

      dialogWeight: false,
      dialogSeason: false,
      dialogSet: false,

      setData: {},
      season_setData: {},
      control_setData: {},
      msgFlag: true,
    };
  },
  methods: {
    newSeason(){
      this.$store.commit("resMessage","");
      this.season_setData = {company_cd: this.company.code,
                            farm_cd: this.farm.code,
                            building_cd: this.building.code,
                            building_name: this.building.name,
                            season_name: "",
                            season_from: "",
                            season_to: "",
                            description:"",
                            history:"",
                            season_id:0 };
    },
    updateSeasonRange(item) {
      if (!this.edit_mode) return;
      this.$store.commit("resMessage","");
      this.season_setData = {company_cd: this.company.code,
                            farm_cd: this.farm.code,
                            building_cd: this.building.code,
                            building_name: this.building.name,
                            season_name: item.season_name,
                            season_from: dateUtil.format(new Date(), "yyyy-")+item.season_from,
                            season_to: dateUtil.format(new Date(), "yyyy-")+item.season_to,
                            season_id: item.id,
                            description: item.descripton,
                            history: item.history,
                          };
      this.dialogSeason = true;
    },
    newWeight() {
      this.$store.commit("resMessage","");
      this.setData = {company_cd: this.company.code,
                      farm_cd: this.farm.code,
                      building_cd: this.building.code,
                      building_name: this.building.name,
                      weight_name: "",
                      weight_min: 0,
                      age_min: 0,
                      age_max: 0,
                      weight_max: 0,
                      description: "",
                      weight_id: 0,
                      history: "",
                      };
    },
    updateWeightRange(item) {
      if (!this.edit_mode) return;
      this.$store.commit("resMessage","");
      this.setData = {company_cd: this.company.code,
                      farm_cd: this.farm.code,
                      building_cd: this.building.code,
                      building_name: this.building.name,
                      weight_id: item.id,
                      weight_name: item.weight_name,
                      weight_min: item.weight_min,
                      weight_max: item.weight_max,
                      age_min: item.age_min,
                      age_max: item.age_max,
                      description: item.descripton,
                      history: item.history,
                    };
      this.dialogWeight = true;
    },

    updateControlRange(weight, season){
      // console.log(weight, season);

      if (!this.edit_mode) return;
      this.$store.commit("resMessage","");
      this.control_setData = {company_cd: this.company.code,
                              farm_cd: this.farm.code,
                              building_cd: this.building.code,
                              building_name: this.building.name,

                              season_id: season.season_id,
                              season_name: season.season_name,
                              weight_id: weight.weight_id,
                              weight_name: weight.weight_name,

                              fan: {on_off1 : true,
                              on_off2: true,
                              on_off3: true,
                              set_temp1: season.fan && season.fan.set_temp1 || 0, 
                              dev_temp1: season.fan && season.fan.dev_temp1 || 0, 
                              max_vent1: season.fan && season.fan.max_vent1 || 0, 
                              min_vent1: season.fan && season.fan.min_vent1 || 0 ,
                              set_temp2: season.fan && season.fan.set_temp2 || 0, 
                              dev_temp2: season.fan && season.fan.dev_temp2 || 0, 
                              max_vent2: season.fan && season.fan.max_vent2 || 0, 
                              min_vent2: season.fan && season.fan.min_vent2 || 0,
                              set_temp3: season.fan && season.fan.set_temp3 || 0, 
                              dev_temp3: season.fan && season.fan.dev_temp3 || 0, 
                              max_vent3: season.fan && season.fan.max_vent3 || 0, 
                              min_vent3: season.fan && season.fan.min_vent3 || 0},

                              heater:{ set_temp1: season.heater && season.heater.set_temp1 || 0, 
                              dev_temp1: season.heater && season.heater.dev_temp1|| 0, 
                              max_vent1: season.heater && season.heater.max_vent1 || 0, 
                              min_vent1: season.heater && season.heater.min_vent1 || 0,
                              on_off1: season.heater && season.heater.on_off1 || false,}
                              };
      this.dialogSet = true;
    },

    dateMD(md) {
        let m = md.slice(0, 2);
        let d = md.slice(3);
        m = (m.slice(0, 1) == "0") ? m.slice(1) : m;
        d = (d.slice(0, 1) == "0") ? d.slice(1) : d;
        return m + "/" + d;
    },
    refresh() {
      this.dialogWeight = false;
      this.dialogSeason = false;
      this.dialogSet = false;

      this.msgFlag = false;
      this.refreshList();
    },

    refreshList() {
      if (this.msgFlag) this.$store.commit("resMessage","");
      if (!this.building || !this.building.code) {
          alert("축사/건물/구역를 선택하세요");
          this.$refs.building.focus();
          return;
      }
      this.loading = true;
      Apis.listControlRangeSettings({
          building_cd: this.building && this.building.code || "",
      }, (res) => {
          this.header_weight = [];
          this.header_season = [];
          this.weight_list = res.data;
          this.weight_list.forEach(item => {
              this.header_weight.push({ id: item.weight_id, weight_name: item.weight_name,
                                        weight_min: item.weight_min, weight_max: item.weight_max,
                                        age_min: item.age_min, age_max: item.age_max,
                                        descripton: item.w_description, history: `${item.w_reguser} / ${item.w_regdate} 등록` });
          });
          // this.weight_list[0] && this.weight_list[0].season_list[0].season_id && this.weight_list[0].season_list.forEach(item => {
          //     this.header_season.push({ id:item.season_id, season_name: item.season_name, 
          //                               season_from: item.season_from, season_to: item.season_to,
          //                               descripton: item.s_description, history: `${item.s_reguser} / ${item.s_regdate} 등록`  });
          // });
          if (this.weight_list[0] && this.weight_list[0].season_list[0].season_id) {
           for (let i in this.weight_list[0].season_list) {
              this.header_season.push(
                { id: this.weight_list[0].season_list[i].season_id, season_name: this.weight_list[0].season_list[i].season_name, 
                  season_from: this.weight_list[0].season_list[i].season_from, season_to: this.makeSeasonTo(i),
                  descripton: this.weight_list[0].season_list[i].s_description,
                  history: `${this.weight_list[0].season_list[i].s_reguser} / ${this.weight_list[0].season_list[i].s_regdate} 등록`  }
              );
            }
          }
          if (this.msgFlag) this.$store.commit("resMessage",res.message);
          this.loading = false;
          this.msgFlag = true;
          let cookie_info = { company: this.company, farm: this.farm, building: this.building };
          VueCookies.set("controltable_info", cookie_info, "30d");
      }).catch((err) => {
          console.log("listControlRangeSettings 호출 오류", err);
          this.loading = false;
      });
    },
    weightDuplicate(id,name,w_min,a_min,callback) {
      let res = "";
      for (let i in this.header_weight) {
        if (this.header_weight[i].id !== id) {
          if (this.header_weight[i].weight_name == name) {res = "구간명이 중복됩니다"; break;}
          if (this.header_weight[i].weight_min == w_min) {res = "체중하한이 중복됩니다"; break;}
          if (this.header_weight[i].age_min == a_min) {res = "주령하한이 중복됩니다"; break;}
        }
      }
      return callback(res);
    },
    seasonDuplicate(id,s_from,callback) {
      let res = "";
      for (let i in this.header_season) {
        if (this.header_season[i].id !== id) {
          if (this.header_season[i].season_from == s_from) {res = "계절 시작일이 중복됩니다"; break;}
        }
      }
      return callback(res);
    },
    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },
    async changeCompany() {   // 하위 콤보 지우기;
      this.farm = {}; 
      this.building = {};

      if (this.company && this.company.code) {   
        await this.comboFarms();
        if (this.company) this.farm = this.farmItems[0];
      }
    },    
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
      this.building = {}; this.buildingItems = [];
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
        this.refreshList();
      }
    },
    makeSeasonTo(index) {      // 다음 시즌 시작일의 하루 전날로 까지날짜 세팅
      let n_from = year + this.weight_list[0].season_list[0].season_from;
      if (index < this.weight_list[0].season_list.length - 1 ) {
        n_from =  year + this.weight_list[0].season_list[Number(index) + 1].season_from;
      } 
      return dateUtil.format(dateUtil.addDays(new Date(n_from), -1),"MM-dd"); 
    },
    
  },
}
</script>


<style lang="scss" scoped >
.table{
  overflow-y: scroll;
  overflow-x: scroll;
}

#seasonTable{
  // display: flex;
  // position:relative;
  flex-wrap: wrap;
  text-align: center;
  // overflow:auto;
}

.season_header{
  // position: absolute;
  display:flex;
  position:relative;
}

.weight_header{
  float:left;
  margin-right: 3px;
  font-size:15px;
  letter-spacing: -1px;
  width:150px;
}

.controlContents{
  position: absolute;
  float:left;
  overflow:hidden;
  // position: relative;
  // display: flex;
  flex-wrap: wrap;
  // width:100%;
  margin-left:-10px;
}

.header_date{
  font-size:15px;
  letter-spacing: -1px;
  font-weight: normal !important;
}

.weight_des{
  font-size:15px;
  letter-spacing: -1px;
  font-weight: normal !important;
}

.table_title{
  width:130px !important;
  height:50px;
  margin:3px 5px;
  padding:20px;
  font-weight: bold !important;
  color:grey !important;
}

.season_section{
  width:145px;
  height:50px;
  margin:3px 5px 0;
  border-radius: 5px;
  padding:10px !important;
  line-height:17px;
  font-weight: bold !important;
  background-color: #e8e8e8 !important;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.2);
}

.season_btn{
  border-radius:5px;
  margin:4px 4px 5px 4px!important;
  border-color:transparent;
  background-color: #3a4f3f !important;
  color:white !important;
  font-weight: bold !important;
}

.weight_section{
  // display:flex;
  width:130px;
  height:100px;
  margin:5px 10px;
  margin-bottom:15px;
  font-weight:bold !important;
  line-height:18px !important;
  padding: 25px 30px !important;;
  border-radius: 5px;
  background-color: #e8e8e8 !important;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
}
.cursorpointer{
  cursor: pointer;
  box-shadow: 2px 3px 4px 0px #bebebe !important;
}

.set_click:focus{
  border:1px solid #3a4f3f !important;
  box-shadow: 2px 3px 4px 0px #3a4f3f;
  border-radius: 5px;
}

.setting{
  width: 145px;
  height: 100px;
  margin: 5px 8px 10px 2px;
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.fc_info{
  padding:5px;
  border: solid 1px grey !important;
  border-radius: 5px;
  font-size:15px;
  line-height:20px; 
  letter-spacing: -1px !important;
}

.heater_info{
  padding:3px;
  border: solid 1px grey !important;
  border-radius: 5px;
  font-size:15px;
  line-height:20px;
  letter-spacing: -1px !important;
}

.set_info{
  width: 130px;
  height: 100px;
  margin:4px 1px 9px;
  padding-bottom:0px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.fan{
  padding:25px;
  height:70px;
  margin-bottom:2px;
  background-color: #e8e8e8 !important;
  border-radius: 5px;
  font-size:15px;
  line-height:20px; 
  letter-spacing: -1px !important;
}

.heater{
  padding:5px;
  height:27px;
  background-color: #e8e8e8 !important;
  border-radius: 5px;
  font-size:15px;
  line-height:20px;
  letter-spacing: -1px !important;
}

.normal-btn{
  background-color: #3a4f3f!important;
  color:white !important;
  font-weight: bold !important;
}
.modify_btn{
  background-color: #3a4f3f!important;
  color:white !important;
  font-weight: bold !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  letter-spacing: -0.5px;
  border-color: transparent;
}

.weight_btn{
  margin:1px 6px;
  margin-top:-5px;
  border-color:transparent;
  background-color: #3a4f3f !important;
  color:white !important;
  font-weight: bold !important;
}
.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
}


</style>



